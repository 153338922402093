/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 35번째 로그에서는 @ecleya 님과 함께 Seocho.rb, Salck 상장, 데이터센터 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/318"
  }, "Seocho.rb 두 번째 모임 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/88245"
  }, "겁나 빠른 카카오톡, 아이폰에서도")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zzerjae.github.io/"
  }, "ZZERJAE – Devlog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/318"
  }, "Seocho.rb 두 번째 모임 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/textract/"
  }, "Amazon Textract | 텍스트 및 데이터 추출 | AWS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.podbbang.com/ch/9596"
  }, "팟캐스트 오디오북 소라소리 방송듣기, 도서 : 팟빵")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://railscasts.com/"
  }, "Ruby on Rails Screencasts - RailsCasts")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/WORK?p=WORK&.tsrc=fin-srch%22"
  }, "Slack Technologies, Inc. (WORK) Stock Price, Quote, History & News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://finance.yahoo.com/quote/WORK?p=WORK&.tsrc=fin-srch"
  }, "Slack Technologies (WORK) Stock Price, Quote, History & News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://corporate.m3.com/"
  }, "(일본어) 엠쓰리 주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jp.corp-sansan.com/"
  }, "(일본어) Sansan 주식회사")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dt.co.kr/contents.html?article_no=2019061402109931033004&ref=naver"
  }, "네이버, 용인 데이터센터 건립 전격 철회...주민들, 전자파 발생 이유 반대 - 디지털타임스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://naver.me/5GbPAnHW"
  }, "네이버 지도 - 공세 초등학교")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://datacenter.navercorp.com/ko/index.html"
  }, "네이버 데이터센터 ‘각’")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://biz.chosun.com/site/data/html_dir/2019/06/22/2019062200123.html"
  }, "용인이 퇴짜 놓은 네이버 데이터센터, 5개市가 러브콜 - Chosunbiz > 산업 > 기업")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/14606751?scode=032&OzSrank=1"
  }, "플래시 보이스 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2019-06-17-github-acquires-pull-panda/"
  }, "GitHub acquires Pull Panda—a better way to collaborate on code reviews - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.pangyo-cev.or.kr/pangyov/main/index.do"
  }, "판교 제2테크노밸리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankookilbo.com/News/Read/201808071046043414"
  }, "제3 판교테크노밸리 최종 승인… 2023년 준공")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.blog/2019-06-17-github-acquires-pull-panda/"
  }, "GitHub acquires Pull Panda—a better way to collaborate on code reviews - The GitHub Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/marketplace/pull-panda"
  }, "Pull Panda · GitHub Marketplace")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/growing-sharing-open-source-wisdom-globally/"
  }, "“엔터프라이즈에서 오픈소스” 한국어 번역본 공개 | Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko"
  }, "프로그래밍 클라우드 기술 블로그 — 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2019/06/06/barack-michelle-obama-spotify/"
  }, "Barack and Michelle Obama are developing podcasts for Spotify | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.makernews.co.kr/news/articleView.html?idxno=1532"
  }, "메이커 페어 운영사, 메이커 미디어 파산 - 메이커뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://make.co.kr/"
  }, "홈 - Make: Korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/nacyot/yasin-peurojegteu-yagu-deiteo-sigaghwa-peurojegteu"
  }, "야신 프로젝트 - 야구 데이터 시각화 프로젝트 - Speaker Deck")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/996icu/996.ICU"
  }, "996icu/996.ICU: Repo for counting stars and contributing. Press F to pay respect to glorious developers.")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
